<script lang="ts" setup>
import type { WeekTimes } from '~/models';

import WeekHours from './WeekHours.vue';

const props = defineProps<{
  collectionTimes: WeekTimes;
  deliveryTimes: WeekTimes;
}>();

const { isMobile } = useDevice();

const tabs = [{
  slot: 'collection',
  label: 'Collection'
}, {
  slot: 'delivery',
  label: 'Delivery'
}];
</script>

<template>
  <div
    v-if="isMobile"
    class="opening-hours"
  >
    <h2 data-test="title">Opening Times</h2>

    <UTabs
      :items="tabs"
      data-test="tabs"
    >
      <template #collection>
        <WeekHours
          :times="props.collectionTimes"
          data-test="collection-tab-hours"
        />
      </template>

      <template #delivery>
        <WeekHours
          :times="props.deliveryTimes"
          data-test="delivery-tab-hours"
        />
      </template>
    </UTabs>
  </div>

  <div
    v-else
    class="flex sm:gap-20 lg:gap-0 sm:px-20 lg:px-0 sm:items-center text-sm py-10"
  >
    <WeekHours
      class="flex-1"
      title="Collection Hours"
      :times="props.collectionTimes"
      data-test="collection-hours"
    />

    <WeekHours
      class="flex-1"
      title="Delivery Hours"
      :times="props.deliveryTimes"
      data-test="delivery-hours"
    />
  </div>
</template>

<style lang="scss" scoped>
body.mobile {
  .opening-hours {
    display: flex;
    flex-direction: column;

    &>h2:first-child {
      padding: 1rem 0 0.5rem 0;
      font-size: 20pt;
      text-align: center;
    }
  }
}
</style>
